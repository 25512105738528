import * as React from 'react'
import { NavLink } from 'react-router-dom'

import { styled, useTheme, makeStyles } from '@material-ui/styles'
import { Toolbar, Button } from '@material-ui/core'
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'
import Box from '@material-ui/core/Box'
import Collapse from '@material-ui/core/Collapse'
import MuiAppBar from '@material-ui/core/AppBar'
import MuiDrawer from '@material-ui/core/Drawer'
import ListItem from '@material-ui/core/ListItem'
import List from '@material-ui/core/List'

import StyledSVG from '../shared/StyledSVG'
import logOut from '../../assets/icons/logout.svg'
import closeSideBarArrow from '../../assets/icons/arrowlfts.svg'
import socioemotionalSVG from '../../assets/icons/mini-socioemotional.svg'
import announcementsSVG from '../../assets/icons/mini-announcements.svg'
import instagramSVG from '../../assets/icons/instagram.svg'
import youtubeSVG from '../../assets/icons/youtube.svg'
import facebookSVG from '../../assets/icons/facebook.svg'
import messagesSVG from '../../assets/icons/mini-messages.svg'
import reportsSVG from '../../assets/icons/mini-reports.svg'
// import classroomsSVG from '../../assets/icons/mini-classrooms.svg'
import teacherSpacingSVG from '../../assets/icons/teacher-spacing.svg'
import utilitiesSVG from '../../assets/icons/mini-utilities.svg'
import configSVG from '../../assets/icons/mini-config.svg'
import { roles } from '../../utils/constants'

const useStyles = makeStyles(theme => ({
  appBar: {
    border: 'none !important',
    boxShadow: 'none !important',
    width: '66px'
  },
  miniLogo: {
    width: '60px',
    marginTop: '32px',
    cursor: 'pointer'
  },
  menuContent: {
    display: 'flex',
    flexDirection: 'column',
    listStyle: 'none',
    marginRight: '29px',
    overflow: 'auto',
    padding: 0,
    width: '100%',
    '&::-webkit-scrollbar': {
      width: '0px'
    }
  },
  menuLi: {
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'flex-start',
    padding: '0px 0px 0px 24px',
    '& a': {
      display: 'flex'
    },
    '& span': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      transition: '0.2s ease-in-out'
    },
    '& span:first-of-type': {
      width: '24px',
      height: '24px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      borderRadius: '4px'
    }
  },
  collapseBody: {
    marginLeft: 58,
    '& .Mui-selected': {
      color: `${theme.palette.secondary.main} !important`,
      backgroundColor: 'unset !important'
    },
    '& div': {
      '& div': {
        '& div': {
          '& a': {
            color: '#ffffff',
            fontWeight: 400,
            fontSize: 14,
            transition: '0.3s',
            '&:hover': {
              color: theme.palette.secondary.main,
              fontWeight: 600
            }
          }
        }
      }
    }
  },
  subMenuHover: {
    '&:hover': {
      color: `${theme.palette.secondary.main} !important`,
      fontWeight: '600 !important'
    }
  },
  logoutp: {
    color: '#fff',
    fontSize: 14,
    fontWeight: 400,
    textTransform: 'capitalize',
    marginLeft: 18,
    '&:hover': {
      color: theme.palette.secondary.main,
      fontWeight: 600
    }
  },
  listItemContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: 10,
    padding: 8,
    borderRadius: '8px',
    '&:hover': {
      backgroundColor: theme.palette.secondary.main,
      '& span': {
        color: 'white'
      }
    }
  },
  nested: {
    color: 'white !important'
  },
  drawerRoot: {
    '& .MuiDrawer-paper': {
      display: 'flex',
      justifyContent: 'space-between',
      overflow: 'auto',
      '&::-webkit-scrollbar': {
        width: '0px'
      }
    }
  },
  logoutButton: {
    '& svg > path': {
      stroke: '#FFFFFF'
    },
    display: 'flex',
    justifyContent: 'flex-start',
    marginLeft: 27,
    transition: 'transform 0.3s ease',
    '&:hover': {
      transform: 'translateY(-5px) translateY(0)',
      backgroundColor: 'unset'
    }
  },
  horizontalBar: {
    width: '70%',
    height: '1px',
    backgroundColor: '#FFFF',
    transition: 'width 0.3s ease-in-out'
  },
  footerSection: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: 50,
    height: '25vh'
  },
  floatBtn: {
    width: 24,
    height: 24,
    position: 'fixed',
    borderRadius: '50%',
    backgroundColor: '#9DD2DA',
    zIndex: 9999,
    top: '15%',
    transition: 'all 0.2s ease-in-out',
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  floatBtnResponsive: {
    [theme.breakpoints.down('900')]: {
      left: '176px !important'
    }
  }
}))

const drawerWidth = 240

const openedMixin = theme => ({
  width: drawerWidth,
  background: theme.palette.primary.main,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen
  }),
  overflowX: 'hidden',
  [theme.breakpoints.down('900')]: {
    width: drawerWidth - 50
  }
})

const closedMixin = theme => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen
  }),
  overflowX: 'hidden',
  width: '100px !important',
  background: theme.palette.primary.main,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(10)} + 1px)`
  }
})

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar
}))

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: prop => prop !== 'open'
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  ...(open && {
    ...openedMixin(theme),
    '& .MuiDrawer-paper': openedMixin(theme)
  }),
  ...(!open && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': closedMixin(theme)
  })
}))

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: prop => prop !== 'open'
})(({ theme, open }) => ({
  right: 'auto',
  width: 66,
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen
  }),
  ...(open && {
    marginRight: drawerWidth,
    width: `calc(66px - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    }),
    [theme.breakpoints.down('900')]: {
      marginRight: drawerWidth - 50,
      width: `calc(66px - ${drawerWidth - 50}px)`
    }
  })
}))

const MiniDrawer = ({
  openSidebar,
  logo,
  miniLogo,
  logout,
  occupations,
  frontVersion,
  setConfigExpanded,
  setSocioExpanded,
  sidebarControl,
  school,
  teacherNonContact
}) => {
  const theme = useTheme()
  const classes = useStyles()
  const isTeacher = occupations?.includes(roles.TEACHER)
  const accessClassrooms = [
    roles.VERSAR_ADMIN,
    roles.SCHOOL_ADMIN,
    roles.TEACHER,
    roles.COORDINATOR
  ]
  const accessTemplates = [roles.VERSAR_ADMIN]
  const accessPublishContent = [roles.SCHOOL_ADMIN]
  const accessPublishContentAdmin = [roles.VERSAR_ADMIN]
  const accessAnnouncementsAndReports = [
    roles.VERSAR_ADMIN,
    roles.SCHOOL_ADMIN,
    roles.TEACHER,
    roles.COORDINATOR
  ]
  const accessUtils = [
    roles.VERSAR_ADMIN,
    roles.SCHOOL_ADMIN,
    roles.TEACHER,
    roles.COORDINATOR
  ]
  const accessMaterials = [
    roles.VERSAR_ADMIN,
    roles.SCHOOL_ADMIN,
    roles.TEACHER,
    roles.COORDINATOR
  ]
  const accessMessagesAdmin = [roles.VERSAR_ADMIN]
  const accessMessages = [
    roles.SCHOOL_ADMIN,
    roles.TEACHER,
    roles.SCHOOL_MODERATOR,
    roles.CLASSROOM_MODERATOR,
    roles.COORDINATOR
  ]
  const projectId = process.env.REACT_APP_PROJECT_ID

  const hasAccess = permissionList => {
    const hasAccess = occupations
      ? occupations.filter(occ => permissionList.some(permit => occ === permit))
      : []
    return hasAccess.length > 0
  }
  const menuOptions = [
    {
      key: 'announcements',
      label: 'Comunicados',
      icon: (
        <StyledSVG
          src={announcementsSVG}
          height={20}
          width={20}
          color={'white'}
        />
      ),
      path: '/announcements?name=list&event=communications',
      hasAccess: hasAccess(accessAnnouncementsAndReports)
    },
    {
      key: 'messages',
      label: 'Mensagens',
      icon: (
        <StyledSVG src={messagesSVG} height={20} width={20} color={'white'} />
      ),
      path: '/messages',
      hasAccess: hasAccess(accessMessages)
    },
    {
      key: 'messages',
      label: 'Mensagens',
      icon: (
        <StyledSVG src={messagesSVG} height={20} width={20} color={'white'} />
      ),
      path: '/messages/schools',
      hasAccess: hasAccess(accessMessagesAdmin)
    },
    {
      key: 'reports',
      label: 'Diário de turmas',
      icon: (
        <StyledSVG src={reportsSVG} height={20} width={20} color={'white'} />
      ),
      path: '/reports?name=history',
      hasAccess: hasAccess(accessAnnouncementsAndReports)
    },
    {
      key: 'utilities',
      label: 'Mural',
      icon: (
        <StyledSVG src={utilitiesSVG} height={20} width={20} color={'white'} />
      ),
      path: '/utils?name=list&util=menus',
      hasAccess: hasAccess(accessUtils)
    },
    {
      key: 'materials',
      label: 'Espaço do professor',
      icon: (
        <StyledSVG
          src={teacherSpacingSVG}
          height={20}
          width={20}
          color={'white'}
        />
      ),
      path: '/materials',
      hasAccess: hasAccess(accessMaterials)
    },
    {
      key: 'config',
      label: 'Configurações',
      icon: (
        <StyledSVG src={configSVG} height={20} width={20} color={'white'} />
      ),
      path: '/settings',
      hasAccess: true
    }
    // {
    //   key: 'socio',
    //   label: 'Socioemocional',
    //   icon: (
    //     <StyledSVG
    //       src={socioemotionalSVG}
    //       height={20}
    //       width={20}
    //       color={'white'}
    //     />
    //   ),
    //   path: '/socioemotional',
    //   hasAccess: true
    // }
  ]

  const handleCollpaseSocio = res => {
    setSocioExpanded(!sidebarControl.socio.open)
    openSidebar(true)
  }

  const handleLogoClick = () => {
    openSidebar(true)
    if (openSidebar && sidebarControl?.open) {
      openSidebar(false)
    }
  }

  React.useEffect(
    () => {
      if (sidebarControl?.open === false) {
        setConfigExpanded(false)
        setSocioExpanded(false)
      }
    },
    [sidebarControl?.open]
  )

  function extractPath (input) {
    const parts = input.split('/')
    if (parts.length === 1) {
      return '/' + parts[0]
    } else {
      const restOfPath = parts.slice(1).join('/')
      const recursiveResult = extractPath(restOfPath)
      return `/${parts[0]}${recursiveResult !== '/' ? recursiveResult : ''}`
    }
  }
  const extractedPath = extractPath(
    location?.pathname.slice(1, location?.pathname.length)
  )
  const extractedPathsForSocio = [
    '/modules',
    '/themes',
    '/contents',
    '/theme-schedule/grades',
    '/classrooms',
    '/socioemotional'
  ]

  return (
    <Box
      style={{
        display: 'flex',
        maxHeight: '100vh',
        overflow: 'none',
        justifyContent: 'space-between',
        gridArea: 'sidebar'
      }}
    >
      <AppBar
        position='fixed'
        open={sidebarControl?.open}
        className={classes.appBar}
      >
        <Toolbar
          style={{
            ...(sidebarControl?.open && { display: 'none' }),
            backgroundColor: theme.palette.primary.main
          }}
        >
          <img
            onClick={handleLogoClick}
            src={miniLogo}
            className={classes.miniLogo}
          />
        </Toolbar>
      </AppBar>
      <Drawer
        variant='permanent'
        open={sidebarControl?.open}
        className={classes.drawerRoot}
      >
        <DrawerHeader
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            marginTop: '25px'
          }}
        >
          <img
            style={{
              cursor: 'pointer',
              ...(!sidebarControl?.open && { display: 'none' })
            }}
            onClick={handleLogoClick}
            src={logo}
            width={170}
            className={classes.fullLogo}
          />
        </DrawerHeader>
        <div
          id={'float-button'}
          className={`${classes.floatBtn} ${sidebarControl?.open &&
            classes.floatBtnResponsive}`}
          style={{
            left: sidebarControl?.open ? '226px' : '86px'
          }}
          onClick={handleLogoClick}
        >
          <img
            src={closeSideBarArrow}
            alt='closeSideBarArrow'
            style={{
              width: 12,
              height: 12,
              transform: !sidebarControl?.open
                ? 'rotate(180deg)'
                : 'rotate(0deg)',
              transition: 'all 0.2s ease-in-out'
            }}
          />
        </div>
        <List className={classes.menuContent}>
          <div style={projectId.toString() === '42' ? { display: 'none' } : {}}>
            <ListItem
              className={classes.menuLi}
              id={'socioemotional'}
              onClick={handleCollpaseSocio}
            >
              <div
                style={{
                  backgroundColor: `${
                    extractedPathsForSocio.includes(extractedPath)
                      ? theme.palette.secondary.main
                      : ''
                  }`,
                  width: sidebarControl?.open ? '85%' : '56%'
                }}
                className={classes.listItemContainer}
              >
                <span>
                  <StyledSVG
                    src={socioemotionalSVG}
                    height={20}
                    width={20}
                    color={'white'}
                  />
                </span>
                <span
                  style={{
                    color: '#fff',
                    fontWeight: `${
                      extractedPathsForSocio.includes(extractedPath) ? 600 : 400
                    }`,
                    transition: 'opacity 0.2s ease-in-out',
                    opacity: sidebarControl?.open ? 1 : 0
                  }}
                >
                  Socioemocional
                  {sidebarControl.socio.open ? (
                    <ArrowDropUpIcon />
                  ) : (
                    <ArrowDropDownIcon />
                  )}
                </span>
              </div>
            </ListItem>
            <Collapse
              className={classes.collapseBody}
              in={sidebarControl.socio.open}
              timeout={'auto'}
              unmountOnExit
            >
              <List component={'div'} disablePadding>
                {!isTeacher && (
                  <ListItem
                    component={NavLink}
                    to={'/socioemotional'}
                    className={`${classes.link} ${classes.nested}`}
                    activeClassName='Mui-selected'
                  >
                    Conteúdos
                  </ListItem>
                )}
                {hasAccess(accessPublishContent) && (
                  <ListItem
                    component={NavLink}
                    className={`${classes.nested} ${classes.subMenuHover}`}
                    to={'/theme-schedule/grades'}
                    activeClassName='Mui-selected'
                  >
                    Publicar conteúdo
                  </ListItem>
                )}
                {hasAccess(accessPublishContentAdmin) && (
                  <ListItem
                    component={NavLink}
                    className={`${classes.nested} ${classes.subMenuHover}`}
                    to={'/theme-schedule/schools'}
                    activeClassName='Mui-selected'
                  >
                    Publicar conteúdo
                  </ListItem>
                )}
                {hasAccess(accessTemplates) && (
                  <ListItem
                    component={NavLink}
                    className={`${classes.nested} ${classes.subMenuHover}`}
                    to={'/planning/grades'}
                    activeClassName='Mui-selected'
                  >
                    Templates
                  </ListItem>
                )}
                {hasAccess(accessClassrooms) && (
                  <ListItem
                    component={NavLink}
                    className={`${classes.listItem} ${classes.link}`}
                    to={'/classrooms'}
                    activeClassName='Mui-selected'
                  >
                    Turmas
                  </ListItem>
                )}
              </List>
            </Collapse>
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              gap: 20,
              marginTop: 20,
              marginBottom: 20
            }}
          >
            {menuOptions.map(({ key, label, icon, path, hasAccess }, index) => {
              const isActive = extractedPath === path.split('?')[0]
              if (!hasAccess) return <></>
              if (key === 'messages' && teacherNonContact) return <></>
              return (
                <ListItem
                  component={NavLink}
                  to={
                    key === 'messages'
                      ? { pathname: path, state: { filterBy: 1 } }
                      : String(path)
                  }
                  id={key}
                  style={{
                    borderLeft: `${
                      isActive
                        ? `2px solid ${theme.palette.secondary.main}`
                        : '2px solid transparent'
                    }`
                  }}
                  key={key}
                  className={classes.menuLi}
                >
                  <div
                    style={{
                      backgroundColor: `${
                        isActive ? theme.palette.secondary.main : ''
                      }`,
                      width: sidebarControl?.open ? '85%' : '56%'
                    }}
                    className={classes.listItemContainer}
                  >
                    <span>{icon}</span>

                    <span
                      style={{
                        color: '#fff',
                        fontWeight: `${isActive ? 600 : 400}`,
                        transition: 'opacity 0.2s ease-in-out',
                        opacity: sidebarControl?.open ? 1 : 0
                      }}
                    >
                      {label}
                    </span>
                  </div>
                </ListItem>
              )
            })}
          </div>
        </List>
        <div className={classes.footerSection}>
          <Button
            onClick={logout}
            className={classes.logoutButton}
            style={{
              justifyContent: sidebarControl?.open === false && 'center'
            }}
          >
            {sidebarControl?.open && (
              <>
                <StyledSVG width={14} height={14} src={logOut} />
                <p className={classes.logoutp}>Sair</p>
              </>
            )}
            {!sidebarControl?.open && <img src={logOut} />}
          </Button>
          {occupations?.[0] !== roles.VERSAR_ADMIN &&
            (school?.currentSchool?.school?.instagram_url ||
              school?.currentSchool?.school?.facebook_url ||
              school?.currentSchool?.school?.youtube_url) && (
              <ListItem id={'social-midia'}>
                <div
                  style={{
                    display: 'flex',
                    margin: sidebarControl?.open ? '0px 0px 0px 24px' : 0,
                    flexDirection: 'column'
                  }}
                >
                  <p
                    style={{
                      color: '#FFFFFF',
                      fontSize: 12,
                      opacity: sidebarControl?.open ? 1 : 0,
                      transition: 'opacity 0.3s ease-in-out'
                    }}
                  >
                    Nossas redes
                  </p>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      gap: 12,
                      flexDirection: sidebarControl?.open ? 'row' : 'column'
                    }}
                  >
                    {school?.currentSchool?.school?.instagram_url && (
                      <a
                        style={{ width: 'fit-content' }}
                        target='_blank'
                        href={school?.currentSchool?.school?.instagram_url}
                        rel='noreferrer'
                      >
                        <StyledSVG
                          onClick
                          width={18}
                          height={18}
                          src={instagramSVG}
                        />
                      </a>
                    )}
                    {school?.currentSchool?.school?.facebook_url && (
                      <a
                        style={{ width: 'fit-content' }}
                        target='_blank'
                        href={school?.currentSchool?.school?.facebook_url}
                        rel='noreferrer'
                      >
                        <StyledSVG
                          onClick
                          width={18}
                          height={18}
                          src={facebookSVG}
                        />
                      </a>
                    )}
                    {school?.currentSchool?.school?.youtube_url && (
                      <a
                        style={{ width: 'fit-content' }}
                        target='_blank'
                        href={school?.currentSchool?.school?.youtube_url}
                        rel='noreferrer'
                      >
                        <StyledSVG
                          onClick
                          width={18}
                          height={18}
                          src={youtubeSVG}
                        />
                      </a>
                    )}
                  </div>
                </div>
              </ListItem>
            )}
        </div>
        <footer
          style={{ display: 'flex', justifyContent: 'center', color: '#fff' }}
        >
          {frontVersion}
        </footer>
      </Drawer>
    </Box>
  )
}

export default MiniDrawer
